import React from 'react';
import Gallery from "react-photo-gallery";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

export var dvds = [
    {
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/dvd-frozen-strip.png",
      alt:"Frozen Dvd",
      width: 33, // these are just dummy values. They won't be used, but is to avoid warnings
      height: 141,
      title: "Frozen"
    },
    {
      alt: 'Pink Panther Dvd',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/dvd-pink-panther-strip.png",
      width: 26,
      height: 141,
      title: "Pink Panther"
    },
    {
      alt: 'Lion King Dvd',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/dvd-lion-king-strip.png",
      width: 26,
      height: 141,
      title: "Lion King"
    },
    {
      alt: 'Lord of the Rings Dvd',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/dvd-lord-of-the-rings-strip.png",
      width: 26,
      height: 141,
      title: "Lord of the Rings"
    },
    {
      alt: 'Mission Impossible Dvd',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/dvd-mission-impossible-strip.png",
      width: 26,
      height: 141,
      title: "Mission Impossible"
    },
    {
      alt: 'Mulan Dvd',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/dvd-mulan-strip.png",
      width: 26,
      height: 141,
      title: "Mulan"
    },
    {
      alt: 'Pirates of the Caribbean Dvd',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/dvd-pirates-strip.png",
      width: 26,
      height: 141,
      title: "Pirates of the Caribbean"
    },
    {
      alt: 'Star Wars: The Empire Strikes Back Dvd',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/dvd-star-wars-strip.png",
      width: 26,
      height: 141,
      title: "Star Wars: The Empire Strikes Back"
    },
    {
      alt: 'Indiana Jones and the Temple of Doom Dvd',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/dvd-indiana-jones-strip.png",
      width: 26,
      height: 141,
      title: "Indiana Jones and the Temple of Doom"
    },
    {
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/dvd-harry-potter-strip.png",
      alt:"Harry Potter Dvd",
      width: 26,
      height: 141,
      title: "Harry Potter"
    },
    {
      alt: 'Pokemon Dvd',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/dvd-pokemon-strip.png",
      width: 26,
      height: 141,
      title: "Pokemon"
    },
    {
      alt: 'Toy Story Dvd',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/dvd-toy-story-strip.png",
      width: 26,
      height: 141,
      title: "Toy Story"
    }
  ]

const imgWithClick = { cursor: "grab" };

const Photo = ({ index, onClick, photo, margin, direction, top, left, alt, newWidth, newHeight }) => {
  const imgStyle = { 
    cursor: "grab",
  };
  if (direction === "column") {
    imgStyle.position = "absolute";
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = event => {
    onClick(event, { photo, index });
  };

  return (
    <img
      style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
      {...photo}
      onClick={onClick ? handleClick : null}
      alt={photo.alt}
      width={newWidth}
      height={newHeight}
    />
  );
};

const SortablePhoto = SortableElement(item => 
  <Photo 
    {...item}
    newWidth={"26px"}
    newHeight={"298px"}
   />);

const SortableGallery = SortableContainer(({ items }) => (
  <Gallery 
    photos={items} 
    renderImage={props => 
      <SortablePhoto 
        { ...props }
      />}
  />
));

class SortableDvds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items
    }
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onSortEnd({oldIndex, newIndex}) {
    let newItems = arrayMove(this.state.items, oldIndex, newIndex);
    this.setState({items: newItems});
    this.props.setDvds(newItems);
  };

  render() {
    return (
      <div>
        <SortableGallery 
          items={this.state.items} 
          onSortEnd={this.onSortEnd} 
          axis={"xy"} 
          distance={1}
          width={"600px"}
        />
      </div>
    )
  }
}

export default SortableDvds;