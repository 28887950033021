import React from 'react';
import './dvd.css'

class Dvd extends React.Component {

  render() {
    return (
      <div className='etr-dvd-container'>
        <img 
          src={this.props.imgUrl} 
          alt={this.props.name}
          onClick={this.props.clicked}
        />
      </div>
    );
  }
}

export default Dvd;
