import React, { useState, useEffect } from 'react';
import {Helmet} from 'react-helmet';
import Modal from 'react-modal';
import ReactAudioPlayer from 'react-audio-player';
import InteractiveRoom from '../../components/EscapeTheRoom/InteractiveRoom.jsx'
import CombinationLock from '../../components/EscapeTheRoom/CombinationLock.jsx'
import TimePuzzle from  '../../components/EscapeTheRoom/TimePuzzle.jsx'
import BonusPuzzleLine from  '../../components/EscapeTheRoom/BonusPuzzleLine.jsx'
import '../../components/EscapeTheRoom/bonusPuzzleLine.css'
import BookContainer from './BookContainer.jsx'
import DvdContainer from './DvdContainer.jsx'
import {books} from '../../components/EscapeTheRoom/SortableBooks.jsx'
import {dvds} from '../../components/EscapeTheRoom/SortableDvds.jsx'
import './EscapeTheRoomPage.css'
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import { Route , withRouter} from 'react-router-dom';

class EscapeTheRoomPage extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    var userId = cookies.get('userid')
    if (!userId) {
      userId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
    cookies.set('userid', userId, { path: '/' });
    
    this.state = { 
      shouldRenderPosters: false,
      shouldRenderTeacherDesk: false,
      shouldRenderBooks: false,
      shouldRenderComputer: false,
      shouldRenderCabinet: false,
      shouldRenderSafe: false,
      shouldRenderPlane: false,
      shouldRenderNotes: false,
      shouldRenderTommyBelongings: false,
      shouldRenderUnlockedCabinet: false,
      shouldRenderUnlockedSafe: false,
      shouldRenderKeyInBook: true,
      shouldRenderUnlockedComputer: false,
      shouldRenderUnlockedSmallSafe: false,
      shouldRenderUnlockedBox: false,
      shouldRenderUnlockedPocket: false,
      shouldRenderDvd: false,
      shouldRenderUnlockedDvd: false,
      shouldRenderUnlockedDvdGee: false,
      shouldRenderUnlockedBag: false,
      isTommyBagDigitUnlocked: false,
      isTommyBagCharacterUnlocked: false,
      exploredSpots: new Set(),
      shouldRenderWhereTommy: true,
      shouldShowDarkRoom: false,
      shouldRenderBonus: false,
      shouldRenderFoldedPlane: false,
      books: books,
      dvds: dvds,
      userId: userId,
      finishStatus: false
    };


    const onBackButtonEvent = (e) => {
      e.preventDefault();
      if (!this.state.finishStatus) {
        if (window.confirm("Are you sure you want to exit the game?")) {
          this.setState({ finishStatus: true });
          // your logic
          props.history.push("/");
        } else {
          window.history.pushState(null, null, window.location.pathname);
          this.setState({ finishStatus: false });
        }
      }
    }

    window.history.pushState(null, null, window.location.pathname);
    window.removeEventListener('popstate', onBackButtonEvent); 
    window.addEventListener('popstate', onBackButtonEvent);
  }

  renderPosters() {
    return (
      <Modal 
        isOpen={this.state.shouldRenderPosters}
        onRequestClose={ () => this.setState({ shouldRenderPosters: false }) }
        ariaHideApp={false} 
        >
        <div>
          <h4>Posters on the wall</h4>
          <p>In the room, you see these posters on the wall.</p>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/wallPosters.png" height="400" alt="painting posters"/>
          {!this.state.shouldShowDarkRoom &&
            <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/wallPosters2.png" height="400" alt="science posters"/>
          }
          {this.state.shouldShowDarkRoom &&
            <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/wallGlow.png" width="500" alt="science posters"/>
          }
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/wallPosters3.PNG" alt="no bully poster"/>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/wallPosters4.PNG" alt="welcome poster"/>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/wallPosters5.PNG" alt="respect poster"/>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/wallPosters6.PNG" alt="energy saver poster"/>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/wallPosters7.PNG" alt="encouraging poster"/>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/alphabetColorPoster.png" height="600" alt="alphabet poster"/>
        </div>
      </Modal>
    );
  }

  shouldRenderClassroom() {
    return !(this.state.shouldRenderPosters ||
      this.state.shouldRenderTeacherDesk ||
      this.state.shouldRenderBooks ||
      this.state.shouldRenderComputer ||
      this.state.shouldRenderCabinet ||
      this.state.shouldRenderSafe ||
      this.state.shouldRenderNotes ||
      this.state.shouldRenderTommyBelongings ||
      this.state.shouldRenderDvd ||
      this.state.shouldRenderPlane
      )
  }

  renderTeacherDesk() {
    return (
      <Modal 
        isOpen={this.state.shouldRenderTeacherDesk}
        onRequestClose={ () => this.setState({ shouldRenderTeacherDesk: false }) }
        ariaHideApp={false} 
        >
        <div>
          <h4>Teacher's desk</h4>
          <p>On the desk, there are slips of paper. One says:</p>
          <i>
            The students brought in some posters we have to put up.<br/>
            Tommy brought in Campbell's Soup Cans (Andy Warhol 1962)<br/>
            Illya brought in Girl with a Pearl Earring (Johannes Vermeer 1665)<br/>
            Dijkstra brought in Young Woman with Unicorn (Raphael 1506)
          </i>
          <p>Another one says:</p>
          <i>
            Tommy brought in a skull to scare people. Gave him a detention.
          </i>
          <p>There is also a 3D printed house. Slide it open and you see a USB.</p>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/house.png" alt="3d printed house"/>
        </div>
      </Modal>
    );
  }

  renderBooks() {
    return (
      <Modal 
        isOpen={this.state.shouldRenderBooks}
        onRequestClose={ () => this.setState({ shouldRenderBooks: false }) }
        ariaHideApp={false} 
        >
        <div>
          <BookContainer
            books={this.state.books}
            setBooks={(newBooks) => {this.setState({books: newBooks});}}
            shouldRenderKey={this.state.shouldRenderKeyInBook}
            unlockCabinet={() => {this.setState({shouldRenderUnlockedCabinet: true});}}
          />
        </div>
      </Modal>
    );
  }

  renderComputer() {
    if (this.state.shouldRenderUnlockedComputer) {
      return this.renderUnlockedComputer();
    }
    return (
      <Modal 
        isOpen={this.state.shouldRenderComputer}
        onRequestClose={ () => this.setState({ shouldRenderComputer: false }) }
        ariaHideApp={false} 
        >
        <div>
          <p>There is a computer on the teacher's desk. It is locked with a password hint "Something you drink from".</p>
        </div>
        <CombinationLock 
          lockName={"computer"}
          label={"Enter password: "} 
          password={"winetaps"}
          unlockAction={() => {this.setState({shouldRenderUnlockedComputer: true});}}
          userId={this.state.userId}
          />
      </Modal>
    );
  }

  renderCabinet() {
    if (this.state.shouldRenderUnlockedCabinet) {
      return this.renderUnlockedCabinet();
    }
    return (
      <Modal 
        isOpen={this.state.shouldRenderCabinet}
        onRequestClose={ () => this.setState({ shouldRenderCabinet: false }) }
        ariaHideApp={false} 
        >
        <div>
          <p>The cabinet is locked</p>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/filingCabinet.PNG" alt="filing cabinet"/>
        </div>
      </Modal>
    );
  }

  renderSafe() {
    if (this.state.shouldRenderUnlockedSafe) {
      return this.renderUnlockedSafe();
    }
    return (
      <Modal 
        isOpen={this.state.shouldRenderSafe}
        onRequestClose={ () => this.setState({ shouldRenderSafe: false }) }
        ariaHideApp={false} 
        >
        <div>
          <p>The safe is locked</p>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/largeSafe.PNG" width="300" alt="large safe"/>
          <CombinationLock 
            lockName={"safe"}
            label={"Enter 4 digit combination: "}
            password={"6217"}
            unlockAction={() => {this.setState({shouldRenderUnlockedSafe: true});}}
            userId={this.state.userId}
          />
        </div>
      </Modal>
    );
  }

  renderNotes() {
    return (
      <Modal 
        isOpen={this.state.shouldRenderNotes}
        onRequestClose={ () => this.setState({ shouldRenderNotes: false }) }
        ariaHideApp={false} 
        >
        <div>
          <p>In a notebook, you see paper slips being passed around. One says:</p>
          <i>
            So annoying, can't believe we had to order all these new hardcover books by their author's last names. Why can't we just throw everything on the shelf.
          </i>
          <p>Another one says:</p>
          <i>
            Saturn, Uranus, Neptune spells sun!
          </i>
        </div>
      </Modal>
    );
  }

  renderTommyBelongings() {
    return (
      <Modal 
        isOpen={this.state.shouldRenderTommyBelongings}
        onRequestClose={ () => this.setState({ shouldRenderTommyBelongings: false }) }
        ariaHideApp={false} 
        >
        <div>
          <p>There is a locked box under the chair. There is also bag that appears to be Tommy's. The small pocket is locked with a 4 digit lock. The large pocket is double locked with a 6-character word lock and a 3 digit number lock. </p>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/backpack.png" alt="Tommy's backpack"/>
          {!this.state.shouldRenderUnlockedBox &&
            <div>
              <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/lockedBox.png" width="300px" alt="locked box"/>
              <CombinationLock 
                lockName={"4 digit lock box"}
                label={"4 digit lock box: "}
                password={"1967"}
                unlockAction={() => {this.setState({shouldRenderUnlockedBox: true});}}
                userId={this.state.userId}
              />
            </div>
          }
          {!this.state.shouldRenderUnlockedPocket &&
            <CombinationLock 
              lockName={"4 digit small pocket lock"}
              label={"4 digit small pocket lock: "}
              password={"5032"}
              unlockAction={() => {this.setState({shouldRenderUnlockedPocket: true});}}
              userId={this.state.userId}
            />
          }
          {
            this.state.shouldRenderUnlockedPocket && this.renderUnlockedPocket()
          }
          
          {!this.state.isTommyBagCharacterUnlocked &&
            <CombinationLock 
              lockName={"6 character big pocket lock"}
              label={"6 character big pocket lock: "}
              password={"oracle"}
              unlockAction={() => {this.setState({isTommyBagCharacterUnlocked: true});}}
              userId={this.state.userId}
            />
          }
          {!this.state.isTommyBagDigitUnlocked &&
            <CombinationLock 
              lockName={"3 digit big pocket lock"}
              label={"3 digit big pocket lock: "}
              password={"892"}
              shouldShowHint={(words) => {
                return words[0] === "982" || words[0] === "289" || words[0] === "298" || words[0] === "928" || words[0] === "829";}}
              showHint={() => {alert('What is the correct order?')}}
              unlockAction={() => {this.setState({isTommyBagDigitUnlocked: true});}}
              userId={this.state.userId}
            />
          }
          {
            this.state.isTommyBagDigitUnlocked && this.state.isTommyBagCharacterUnlocked &&
            this.renderUnlockedBag()
          }
          
          
          {
            this.state.shouldRenderUnlockedBox && this.renderUnlockedBox()
          }
        </div>
      </Modal>
    );
  }

  renderUnlockedCabinet() {
    return (
      <Modal 
        isOpen={this.state.shouldRenderCabinet}
        onRequestClose={ () => this.setState({ shouldRenderCabinet: false }) }
        ariaHideApp={false} 
        >
        <div>
          <p>Inside the cabinet opened by the key, you see a <a href="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/studentDirectory.pdf" target="_blank" rel="noopener noreferrer"> student directory.</a></p>
        </div>
      </Modal>
    );
  }

  renderUnlockedSafe() {
    return (
      <Modal 
        isOpen={this.state.shouldRenderSafe}
        onRequestClose={ () => this.setState({ shouldRenderSafe: false }) }
        ariaHideApp={false}>
        <div>
          <p>We see a confiscated skull, another small safe, a worksheet, and some wooden pieces.</p>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/confiscatedSkull.png" alt="confiscated skull"/>
          
          {!this.state.shouldRenderUnlockedSmallSafe &&
            <div>
              <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/smallSafe.PNG" width="500" alt="small safe"/>
              <CombinationLock 
                lockName={"Small box in safe"}
                label={"Enter 4 digit combination: "}
                password={"4701"}
                unlockAction={() => {this.setState({shouldRenderUnlockedSmallSafe: true});}}
                userId={this.state.userId}
              />
            </div>
          }
          {this.state.shouldRenderUnlockedSmallSafe && this.renderUnlockedSmallSafe()}
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/timeWorksheet.png" alt="time worksheet"/>
          <TimePuzzle/>
        </div>
      </Modal>
    );
  }

  renderUnlockedComputer() {
    return (
      <Modal 
        isOpen={this.state.shouldRenderComputer}
        onRequestClose={ () => this.setState({ shouldRenderComputer: false }) }
        ariaHideApp={false} 
        >
        <div>
          <p>Plug in USB and you see this video.</p>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/video.gif" width="400" alt="video of poster"/>
        </div>
      </Modal>
    );
  }

  renderUnlockedSmallSafe() {
    return (
      <div>
        <p>There are some markers in the small safe.</p>
        <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/markers.png" width="400" alt="some markers"/>
      </div>
    );
  }

  renderUnlockedBox() {
    return (
      <div>
        <p>In the unlocked box, there is a piece of <a href="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/weirdSymbols.pdf" target="_blank" rel="noopener noreferrer">paper with weird symbols</a>. Since it is quite difficult to solve this without printing it out, you can also optionally do the following action on the paper to help you out.
        </p>
        {!this.state.shouldRenderFoldedPlane && 
          <CombinationLock 
            lockName={"doActionForThingInBag"}
            label={"Do this optional action: make"}
            password={"paper airplane"}
            unlockAction={() => {this.setState({shouldRenderFoldedPlane: true});}}
            userId={this.state.userId}
          />
        }
        {this.state.shouldRenderFoldedPlane &&
            <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/foldedPlane.png" alt="folded plane"/>
        }
      </div>
    );
  }

  renderUnlockedPocket() {
    return (
      <div>
        <p>There is a recorder in the small pocket.</p>
        <ReactAudioPlayer
          src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/Track1.mp3"
          controls
        />
        <ReactAudioPlayer
          src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/Track2.mp3"
          controls
        />
        <ReactAudioPlayer
          src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/Track3.mp3"
          controls
        />
        <ReactAudioPlayer
          src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/Track4.mp3"
          controls
        />
        <ReactAudioPlayer
          src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/Track5.mp3"
          controls
        />
      </div>
    );
  }

  renderDvd() {
    return (
      <Modal 
        isOpen={this.state.shouldRenderDvd}
        onRequestClose={ () => this.setState({ shouldRenderDvd: false }) }
        ariaHideApp={false} 
        >
        <div>
          <DvdContainer
            dvds={this.state.dvds}
            renderUnlockedDvd={this.state.shouldRenderUnlockedDvd}
            renderUnlockedGee={this.state.shouldRenderUnlockedDvdGee}
            unlockDvd={() => {this.setState({shouldRenderUnlockedDvd: true})}}
            unlockGee={() => {this.setState({shouldRenderUnlockedDvdGee: true})}}
            setDvds={(newDvds) => {this.setState({dvds: newDvds});}}
          />
        </div>
      </Modal>
    );
  }

  renderPlane() {
    return (
      <Modal 
        isOpen={this.state.shouldRenderPlane}
        onRequestClose={ () => this.setState({ shouldRenderPlane: false }) }
        ariaHideApp={false} 
        >
        <div>
          <p>You see a paper plane with nothing on it.</p>
          <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/confiscatedPlane.png" alt="paper plane"/>
        </div>
      </Modal>
    );
  }

  renderUnlockedBag() {
    return (
      <div>
        <p>You've unlocked Tommy's bag! There is a different paper plane. There is nothing on it when you unfold it.</p>
        <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/plane3.jpg" alt="folded paper plane"/>
        <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/plane2.jpg" alt="unfolded paper plane"/>
        <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/plane1.jpg" alt="unfolded paper plane"/>
        <p>Tommy signed a piece of paper with a picture of fire on some stones.</p>
        <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/fireAndStone.png" alt="fire and stone"/>
        <p>Tommy signed another piece of paper with a picture of McDonald.</p>
        <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/mcdonald.png" alt="Mcdonald"/>
        <p>The last piece of paper is <a href="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/princetonCampusMap.pdf" target="_blank" rel="noopener noreferrer">a map of Princeton Campus</a>. The map says "X marks the spot" on it.</p>
      </div>
    );
  }

  shouldRenderDoActionField() {
    return this.state.exploredSpots.size > -1;
  }

  renderBonus() {
    return (
      <div>
        <h2>Bonus puzzle</h2>
        <p>Here, have a poem or whatever the heck this is and then tell me where I am now. You get a word from each line, and then a place from each stanza. You just have to do all 8 puzzles over again.</p>
        <br/>
        <p>The answer you sent to get this <b>(Jones)</b> can be a freebee point, you just need the other three now.  X still marks the spot!</p>
        <br/>
        <br/>
        <BonusPuzzleLine
          line="First look at the shadow of the beasts,"
          answer="sir"
        />
        <BonusPuzzleLine
          line="This one should be obvious at the least!"
          overrideLine={
            <div className="etr-bonus-line-description">
            <i><span className="etr-purple-text">This one</span> <span className="etr-green-text">should be</span> <span className="etr-blue-text">obvious</span> <span className="etr-red-text">at the least</span>!</i>
            </div>}
          answer="mole"
        />
        <BonusPuzzleLine
          line="Go back to the books, it’s the third for this case,"
          answer="inner"
        />
        <BonusPuzzleLine
          line="Those 5 movies were great, put them in the same order in prime place!"
          answer="gee"
        />
        <br/>
        <br/>
        <BonusPuzzleLine
          line="It’s not about who, but with their time what they do. (Then take away what hurts)."
          answer="west"
        />
        <BonusPuzzleLine
          line="How to refold should be clear by now too."
          answer="call"
        />
        <BonusPuzzleLine
          line="What’s her favorite word? Yes! Her! This is your clue."
          answer="ledge"
        />
        <br/>
        <br/>
        <BonusPuzzleLine
          overrideLine={
            <div>
              <div className="etr-bonus-line-description">
                <i>There’s really no change since you turned off the lights.</i>
              </div>
              <div className="etr-bonus-line-description">
                <i>It’s already a place, a place on the map.</i>
              </div>
            </div>
          }
          answer="1967"
        />
      </div>
    );
  }

  renderDoAction() {
    return (
      <div>
        <h4>Action</h4>
        <p>It seems like you have to do some action in the room. </p>
        <CombinationLock 
          lockName={"main action in room"}
          password={"turn off the lights"}
          unlockAction={() => {this.setState({shouldShowDarkRoom: true});}}
          userId={this.state.userId}
        />
      </div>
    );
  }

  renderTurnLightsOn() {
    return (
      <button onClick={() => {this.setState({shouldShowDarkRoom: false});}}>
        Turn lights back on.
      </button>
    );
  }

  renderWhereIsTommy() {
    return (
      <div className="etr-where-is-tommy">
        <h4>Where is Tommy?</h4>
        { !this.state.shouldRenderBonus &&
          <CombinationLock 
            lockName={"whereIsTommy?"}
            password={"jones"}
            unlockAction={() => {
              alert('Good job, you found him! ...but alas he escaped again at the last minute. He left a note behind though. Can you find Tommy again?');
              this.setState({shouldRenderBonus: true})
            }}
            userId={this.state.userId}
          />
        }
        { this.state.shouldRenderBonus &&
          <CombinationLock 
            lockName={"whereIsTommySecondTime"}
            password={"yoseloff"}
            unlockAction={() => {alert("You win! You've caught Tommy again")}}
            userId={this.state.userId}
          />
        }
        <p></p>
      </div>
    );
  }

  render() {
    return (
      <div className="EscapeTheRoomPage">
        <Helmet>
          <meta charSet="utf-8" name="description" content="Princeton escape room is a room designed by five seniors in 2016. This is a web reproduction of the room at the time, and is playable virtually."/>
          <title>Princeton Escape Room</title>
        </Helmet>
        <div className="etr-title-and-description">
          <div className="etr-title-image-container">
            <div>
              <h1>Princeton Escape Room: Where is Tommy?</h1>
              <h3>Background</h3>
              <p>
                A fully playable virtual escape room open to everyone online. This room was originally designed for around 8 Princeton students hosted in a physical classroom on campus back in May 2016. Here is the web reproduction of it, with all objects and puzzles displayed in digital forms. For the best experience, use a desktop when attempting the room.
              </p>
              <p>
                Recommended group size: 8
                <br/>
                Time limit: 1 hour
                <br/>
                One part requires some Princeton context: <span className="etr-princeton-hint"onClick={() => {alert('The four items that belong to Tommy refer to four different Princeton buildings.')}}>click here if you want a hint.</span>
              </p>
              <p>
                Actions you can do on the site are:
                <br/>
                1. click
                <br/>
                2. drag
                <br/>
                3. enter password/action
                <br/>
                4. press escape to exit modal
              </p>
            </div>
            <div>
              <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/escapeTheRoomPoster.png" alt="Princeton Escape Room Event Poster" height="500px"/>
            </div>
          </div>
          <h1>Room Introduction</h1>
          <p>You all fell asleep in class. When you wake up, the room is deserted and the door is locked. Tommy and his gang of delinquents have stolen exams and other valuables from the classroom and plan to frame the ones left behind. But like any other good villain they've left you a way out. Find out where Tommy is and how to get out before the janitors arrive.
          </p>
          {this.state.shouldRenderWhereTommy && this.renderWhereIsTommy()}
          {this.shouldRenderDoActionField() && this.renderDoAction()}
          {this.state.shouldShowDarkRoom && this.renderTurnLightsOn()}
          {this.state.shouldRenderBonus && this.renderBonus()}
          

          {this.shouldRenderClassroom() && 
            <div className="etr-interactive-room">
              <InteractiveRoom
                url={this.state.shouldShowDarkRoom ? 
                  "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/darkRoom.png" : 
                  "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/classroom.png"}
                showPosters={() => {var set = this.state.exploredSpots.add("poster"); this.setState({shouldRenderPosters: true, exploredSpots: set});}}
                showTeacherDesk={() => {var set = this.state.exploredSpots.add("desk"); this.setState({shouldRenderTeacherDesk: true, exploredSpots: set});}}
                showComputer={() => {var set = this.state.exploredSpots.add("computer"); this.setState({shouldRenderComputer: true, exploredSpots: set});}}
                showCabinet={() => {var set = this.state.exploredSpots.add("cabinet"); this.setState({shouldRenderCabinet: true, exploredSpots: set});}}
                shouldRenderPlane={() => {var set = this.state.exploredSpots.add("plane"); this.setState({shouldRenderPlane: true, exploredSpots: set});}}
                canRemoveKeyFromBook={this.state.shouldRenderUnlockedCabinet}
                removeKeyFromBook={() => {this.setState({shouldRenderKeyInBook: false});}}
                showSafe={() => {var set = this.state.exploredSpots.add("safe"); this.setState({shouldRenderSafe: true, exploredSpots: set});}}
                showBooks={() => {var set = this.state.exploredSpots.add("bookshelf"); this.setState({shouldRenderBooks: true, exploredSpots: set});}}
                showDvd={() => {var set = this.state.exploredSpots.add("dvdshelf"); this.setState({shouldRenderDvd: true, exploredSpots: set});}}
                showTommyBelongings={() => {var set = this.state.exploredSpots.add("tommyBelongings"); this.setState({shouldRenderTommyBelongings: true, exploredSpots: set});}}
                showNotes={() => {var set = this.state.exploredSpots.add("notes"); this.setState({shouldRenderNotes: true, exploredSpots: set});}}
                userId={this.state.userId}
              />
            </div>
          }
          {!this.shouldRenderClassroom() && 
            <div className="etr-interactive-room">
            </div>
          }
          {this.state.shouldRenderPlane && this.renderPlane()}
          {this.state.shouldRenderPosters && this.renderPosters()}
          {this.state.shouldRenderBooks && this.renderBooks()}
          {this.state.shouldRenderTeacherDesk && this.renderTeacherDesk()}
          {this.state.shouldRenderComputer && this.renderComputer()}
          {this.state.shouldRenderCabinet && this.renderCabinet()}
          {this.state.shouldRenderSafe && this.renderSafe()}
          {this.state.shouldRenderNotes && this.renderNotes()}
          {this.state.shouldRenderTommyBelongings && this.renderTommyBelongings()}
          
          {this.state.shouldRenderDvd && this.renderDvd()}
        </div>
        <footer className='etr-footer'>
          <h5>
          About the room
          </h5>
          <div>
            Inspired by our trip to New York, we (Eli, Darek, Kenny, Josh, and I) decided to host our own escape the room on campus. We did everything from scratch, from designing the puzzles to building the room to working out the logistics. At the end of our final semester, we rented out a classroom in Friend Center and hosted the event for 8 days.
          </div>
        </footer>
      </div>
    );
  }
}

export default withRouter(withCookies(EscapeTheRoomPage));
