import React from 'react';
import ImageMapper from 'react-image-mapper';
import './timePuzzle.css'

var MAP = {
  name: "my-map",
  areas: [
    { name: "arc0", shape: "poly", coords: [224,24,301,38,368,83,224,224], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)", pieceNumber: 0},
    { name: "arc1", shape: "poly", coords: [368,83,410,141,427,224,224,224], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)", pieceNumber: 1},
    { name: "arc2", shape: "poly", coords: [427,224,413,302,367,370,224,224], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)", pieceNumber: 2},
    { name: "arc3", shape: "poly", coords: [367,370,303,412,224,427,224,224], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)", pieceNumber: 3},
    { name: "arc4", shape: "poly", coords: [224,427,142,411,82,369,224,224], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)", pieceNumber: 4},
    { name: "arc5", shape: "poly", coords: [82,369,42,312,21,225,224,224], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)", pieceNumber: 5},
    { name: "arc6", shape: "poly", coords: [24,225,44,138,83,79,224,224], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)", pieceNumber: 6},
    { name: "arc7", shape: "poly", coords: [83,79,145,40,224,24,224,224], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)", pieceNumber: 7}
  ]
}

class TimePuzzle extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      hoveredArea: null, 
      msg: 'hi',
      placedPieces: {},
      selectedPiece: null, 
      remainingPieces: ["woodenA", "woodenE", "woodenI", "woodenN", "woodenP", "woodenS", "woodenT", "woodenW"]
    };
    this.enterArea = this.enterArea.bind(this);
    this.clicked = this.clicked.bind(this);
    this.moveOnImage = this.moveOnImage.bind(this);
    this.renderPlacedPiece = this.renderPlacedPiece.bind(this);
  }

  enterArea(area) {
    this.setState({
      hoveredArea: area,
      msg: `You entered ${area.name}!`
    });
  }

  clicked(area) {
    if (!this.state.selectedPiece) {
      // remove if something is there.
      if (area.pieceNumber in this.state.placedPieces) {
        let nameToAdd = this.state.placedPieces[area.pieceNumber].baseName
        let remainingPieces = this.state.remainingPieces.slice(0);
        remainingPieces.push(nameToAdd);
        let placedPieces = Object.assign({}, this.state.placedPieces);
        delete placedPieces[area.pieceNumber];
        this.setState({
          remainingPieces: remainingPieces,
          placedPieces: placedPieces
        });
      }
      return;
    }
    // placing over existing spot
    if (area.pieceNumber in this.state.placedPieces) {
      return;
    }  

    let placedPieces = Object.assign({}, this.state.placedPieces);
    placedPieces[area.pieceNumber] = {
      ...area, 
      pieceImgSrc: this.state.selectedPiece.pieceImgSrc,
      pieceNumber: area.pieceNumber,
      baseName: this.state.selectedPiece.baseName
    };

    let remainingPieces = this.state.remainingPieces.slice(0);
    this.setState({
      placedPieces: placedPieces,
      remainingPieces: remainingPieces.filter(x => x !== this.state.selectedPiece.baseName),
      selectedPiece: null
    });
  }

  moveOnImage(evt) {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    this.setState({
      moveMsg: `You moved on the image at coords ${JSON.stringify(coords)} !`
    });
  }

  getTipPosition(pieceNumber) {
    var angleInDegrees = pieceNumber*45 + 22.5;
    var x = 225 + 100*Math.sin(angleInDegrees * Math.PI/180);
    var y = 225 - 100*Math.cos(angleInDegrees * Math.PI/180);
    var transformStr = 'translate3d(-50%, -50%, 0) rotate(' + angleInDegrees + 'deg)';
    return {top: y + 'px', left: x + 'px', transform: transformStr};
  }

  renderPlacedPiece(pieceNumber) {
    return (
      <span 
        key={pieceNumber}
        className="etr-timepuzzle-tooltip"
        style={{ ...this.getTipPosition(pieceNumber)}}>
        <img src={this.state.placedPieces[pieceNumber].pieceImgSrc}/>
      </span>
    );
  }

  renderHoveredPiece() {
    return (
      <span 
        className="etr-timepuzzle-tooltip"
        style={{ ...this.getTipPosition(this.state.hoveredArea.pieceNumber)}}>
        <img src={this.state.selectedPiece.pieceImgSrc}/>
      </span>
    );
  }

  leaveArea(area) {
    this.setState({
      hoveredArea: null
    });
  }

  constructUrl(baseName) {
    return "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/" + baseName + ".png";
  }

  selectPiece(baseName) {
    if (this.state.selectedPiece && this.state.selectedPiece.baseName === baseName) {
      this.setState({selectedPiece: null});
      return;
    }

    this.setState({
      selectedPiece: {baseName: baseName, pieceImgSrc: this.constructUrl(baseName)},
    })
  }

  pieceBackgroundColor(baseName) {
    if (!this.state.selectedPiece) {
      return "white";
    }
    return this.state.selectedPiece.baseName === baseName ? "gray": "white";
  }

  renderPiece(baseName) {
    return (
        <img 
          key={baseName}
          src={this.constructUrl(baseName)} alt={baseName} 
          onClick={() => this.selectPiece(baseName)} 
          style={{cursor: "pointer", background: this.pieceBackgroundColor(baseName) }}/>
      );
  }

  render() {
    return (
      <div className="etr-interactive-puzzle">
        {this.state.remainingPieces.map(baseName => this.renderPiece(baseName))}
        <div className="etr-wooden-container">
          <ImageMapper 
            src={this.constructUrl("woodenOutline")} 
            map={MAP}
            onMouseEnter={area => this.enterArea(area)}
            onMouseLeave={area => this.leaveArea(area)}
            onClick={area => this.clicked(area)}
            onImageMouseMove={evt => this.moveOnImage(evt)}
          />
          {this.state.hoveredArea && this.state.selectedPiece && this.renderHoveredPiece()}
          {Object.keys(this.state.placedPieces).map(pieceNumber => this.renderPlacedPiece(pieceNumber))}
        </div>
      </div>
    );
  }
}

export default TimePuzzle;
