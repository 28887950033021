import React from 'react';
import ImageMapper from 'react-image-mapper';
import './interactiveRoomBox.css'
import {sendEvent} from '../../api/etrCaller.js'

var MAP = {
  name: "my-map",
  areas: [
    { name: "desk", shape: "poly", coords: [62,57,150,57,150,117,62,117], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)"},
    { name: "computer", shape: "poly", coords: [153,72,229,72,229,99,153,99], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)"},
    { name: "cabinet", shape: "poly", coords: [265,70,311,70,311,107,265,108], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)"},
    { name: "posters", shape: "poly", coords: [3,570,399,570,399,599,3,599], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)"},
    { name: "safe", shape: "poly", coords: [350,53,384,53,384,90,350,90], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)"},
    { name: "books", shape: "poly", coords: [10,145,26,145,26,261,10,261], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)"},
    { name: "dvd", shape: "poly", coords: [10,394,41,394,41,486,10,486], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)"},
    { name: "Tommy's Bag", shape: "poly", coords: [66,204,97,204,97,235,66,235], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)"},
    { name: "plane", shape: "poly", coords: [228,204,259,204,259,235,228,235], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)"},
    { name: "notes", shape: "poly", coords: [228,319,259,319,259,350,228,350], strokeColor:"rgba(0, 0, 0, 0.0)", fillColor: "rgba(0, 0, 0, 0.0)"},
  ]
}

class InteractiveRoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hoveredArea: null, msg: 'hi'};
    this.enterArea = this.enterArea.bind(this);
    this.clicked = this.clicked.bind(this);
    this.moveOnImage = this.moveOnImage.bind(this);
    this.renderOverlay = this.renderOverlay.bind(this);
  }

  enterArea(area) {
    this.setState({
      hoveredArea: area,
      msg: `You entered ${area.name}!`
    });
  }

  clicked(area) {
    this.setState({
      msg: `You clicked on ${area.name}!`
    });
    sendEvent(this.props.userId, 'interactive-room', area.name);
    if (area.name === "posters") {
      this.props.showPosters();
    } else if (area.name === "desk") {
      this.props.showTeacherDesk();
    } else if (area.name === "computer") {
      this.props.showComputer();
    } else if (area.name === "cabinet") {
      this.props.showCabinet();
      if (this.props.canRemoveKeyFromBook) {
        this.props.removeKeyFromBook();
      }
    } else if (area.name === "safe") {
      this.props.showSafe();
    } else if (area.name === "books") {
      this.props.showBooks();
    } else if (area.name === "dvd") {
      this.props.showDvd();
    } else if (area.name === "Tommy's Bag") {
      this.props.showTommyBelongings();
    } else if (area.name === "notes") {
      this.props.showNotes();
    } else if (area.name === "plane") {
      this.props.shouldRenderPlane();
    }
  }

  moveOnImage(evt) {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    this.setState({
      moveMsg: `You moved on the image at coords ${JSON.stringify(coords)} !`
    });
  }

  getTipPosition(area) {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  }

  renderOverlay() {
    var className = "etr-interactiveRoomBox-tooltip";
    if (this.state.hoveredArea && this.state.hoveredArea.name === "books") {
      className = "etr-interactiveRoomBox-tooltip-rotate";
    }
    return (
      <div>
        {
          this.state.hoveredArea &&
          <span className={className}
              style={{ ...this.getTipPosition(this.state.hoveredArea)}}>
            { this.state.hoveredArea && this.state.hoveredArea.name}
          </span>
        }
      </div>
    );
  }

  leaveArea(area) {
    this.setState({
      hoveredArea: null
    });
  }

  render() {
    return (
      <div className="interactiveRoomBox">
        <ImageMapper 
          src={this.props.url} 
          map={MAP}
          onMouseEnter={area => this.enterArea(area)}
          onMouseLeave={area => this.leaveArea(area)}
          onClick={area => this.clicked(area)}
          onImageMouseMove={evt => this.moveOnImage(evt)}
        />
        {this.renderOverlay()}
      </div>
    );
  }
}

export default InteractiveRoom;
