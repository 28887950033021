import React from 'react';
import './Homepage.css';
import EscapeTheRoom from '../EscapeTheRoom/EscapeTheRoomPage.jsx';
import FgoPage from '../FGO/FgoPage.jsx';
import {BrowserRouter as Router, Link, Route} from 'react-router-dom'
import {Helmet} from "react-helmet";
import { CookiesProvider } from 'react-cookie';

function RenderHomePage() {
  return (
    <div className="Homepage">
      <Helmet>
        <meta charSet="utf-8" name="description" content="Ben Lee is a developer. Here is his website. "/>
        <title>Ben's website</title>
      </Helmet>
      <header className="Homepage-header">
        Ben Lee
      </header>
      <link rel="icon" href="https://www.benlee.dev/favicon.ico?v=3" />
      <img src="https://dmewnhprjcemh.cloudfront.net/assets/root/me.png" alt="Princeton Escape Room Event Poster" height="500px"/>
      <Link to={`/princeton-escape-room`}>
        <p>
          Princeton Escape Room
        </p>
      </Link>
      <Link to={`/fgo-trivia`}>
        <p>
          FGO trivia site
        </p>
      </Link>
    </div>)
}

function Homepage() {
  return (
    <Router>
      <Route exact={true} path="/" render={() => RenderHomePage()}/>
      <Route exact={true} path="/princeton-escape-room" render={() => (<CookiesProvider><EscapeTheRoom/></CookiesProvider>)}/>
      <Route exact={true} path="/fgo-trivia" render={() => (<FgoPage/>)}/>
    </Router>
  );
}

export default Homepage;
