import React from 'react';
import Gallery from "react-photo-gallery";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";


const imgWithClick = { cursor: "pointer" };

const Photo = ({ index, onClick, photo, margin, direction, top, left, alt }) => {
  const imgStyle = { margin: margin, cursor: "pointer" };
  if (direction === "column") {
    imgStyle.position = "absolute";
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = event => {
    console.log(event.target);
    onClick(event, { photo, index });
  };

  return (
    <img
      style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
      {...photo}
      onClick={onClick ? handleClick : null}
      alt={photo.alt}
    />
  );
};

const SortablePhoto = SortableElement(item => <Photo {...item}/>);
const SortableGallery = SortableContainer(({ items }) => (
  <Gallery photos={items} renderImage={props => <SortablePhoto {...props} />} />
));

class SortableDrawings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          src:"https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/crane.jpg",
          width:3,
          height:4,
          alt:"crane drawing"
        },
        {
          src:"https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/monkey.jpg",
          width:3,
          height:4,
          alt:"monkey drawing"
        },
        {
          src:"https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/snakeResized.jpg",
          width:3,
          height:4,
          alt:"snake drawing"
        }
      ]
    }
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onSortEnd({oldIndex, newIndex}) {
    let newItems = arrayMove(this.state.items, oldIndex, newIndex);
    this.setState({items: newItems});
  };

  render() {
    return (
      <div>
        <SortableGallery items={this.state.items} onSortEnd={this.onSortEnd} axis={"xy"} distance={1}/>
      </div>
      )
  }
}

export default SortableDrawings;
