import React from 'react';
import './combinationLock.css';
import {sendEvent} from '../../api/etrCaller.js'

class CombinationLock extends React.Component {
  constructor(props) {
    super(props);
    var splittedPassword = this.props.password.split(' ');
    this.state = {
      value: '',
      values: splittedPassword.map(x => ''),
      words: splittedPassword
    };

    this.textInputRefs = splittedPassword.map(x => React.createRef());
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.focus = this.focus.bind(this);
    this.handleKeyDown= this.handleKeyDown.bind(this);
  }

  focus(values) {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    var inputToFocus = 0;
    for (var i = 0; i < this.state.words.length; i++) {
      inputToFocus = i;
      if (values[i].length < this.state.words[i].length) {
        break;
      }
    }
    this.textInputRefs[inputToFocus].current.focus();
  }
  
  handleChange(e, i) {
    let values = this.state.values.slice(0);
    values[i] = e.target.value;
    this.focus(values);
    this.setState({values: values});
  }

  handleSubmit(event) {
    var submittedPassword = this.state.values.join(' ').trim();
    if (submittedPassword) {
      sendEvent(this.props.userId, 'combinationLock: ' + this.props.lockName, submittedPassword);
    }
    var newValues = this.state.words.map(x => '');
    for (var i = 0; i < this.state.words.length; i++) {
      if (this.state.words[i].toLowerCase() !== this.state.values[i].toLowerCase()) {
        this.setState({value: '', values: newValues});
        this.focus(newValues);
        if (this.props.shouldShowHint && this.props.shouldShowHint(this.state.values)) {
          this.props.showHint();
        } else {
          alert('incorrect');
        }
        event.preventDefault();
        return;
      }
    }
    this.setState({value: '', values: newValues});
    this.focus(newValues);
    this.props.unlockAction();
    event.preventDefault();
  }

  handleKeyDown(e, i) {
    if (e.key === "Backspace" && i > 0 && !this.state.values[i]) {
      this.textInputRefs[i - 1].current.focus();
    }
  }

  computeStyle(charCount) {
    return {
      width: (charCount*1.5 - 0.5) + "ch",
      border: "none",
      background: 
        "repeating-linear-gradient(90deg, dimgrey 0, dimgrey 1ch, transparent 0, transparent 1.5ch) 0 100%/100% 2px no-repeat",
      color: "dimgrey",
      font: "2ch consolas, monospace",
      letterSpacing: ".5ch",
      margin: "1ch"
    };
  }

  renderWord(word, i) {

    return (
      <input 
        key={word}
        ref={this.textInputRefs[i]}
        className="etr-combination-text-input"
        style={this.computeStyle(word.length)}
        onClick={() => this.focus(this.state.values)}
        value={this.state.values[i]}
        onKeyDown={(event) => this.handleKeyDown(event, i)}
        onChange={(event) => this.handleChange(event, i)} 
        />
      );
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          {this.props.label}
          {this.state.words.map((word, i) => this.renderWord(word, i))}
        </label>
        <input type="submit" value="Submit" />
      </form>
    );
  }
}


export default CombinationLock;
