import React from 'react';
import Dvd from '../../components/EscapeTheRoom/Dvd.jsx'
import SortableDrawings from '../../components/EscapeTheRoom/SortableDrawings.jsx'
import SortableDvds from '../../components/EscapeTheRoom/SortableDvds.jsx'
import './DvdContainer.css'

class DvdContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (!this.props.renderUnlockedDvd &&
      JSON.stringify(this.props.dvds.map((dvd) => dvd.alt).slice(0,5)) === JSON.stringify(['Star Wars: The Empire Strikes Back Dvd', 'Mulan Dvd', 'Mission Impossible Dvd', 'Frozen Dvd', 'Harry Potter Dvd'])
      ) {
      this.props.unlockDvd();
    }

    if (this.props.renderUnlockedDvd && !this.props.renderUnlockedGee &&
      this.props.dvds[1].alt === 'Star Wars: The Empire Strikes Back Dvd' &&
      this.props.dvds[2].alt === 'Mulan Dvd' &&
      this.props.dvds[4].alt === 'Mission Impossible Dvd' &&
      this.props.dvds[6].alt === 'Frozen Dvd' &&
      this.props.dvds[10].alt === 'Harry Potter Dvd')
    {
      this.props.unlockGee();
    }
  }

  renderUnlocked() {
    return (
      <div>
        <div className='etr-dvd-shelf-displacer'></div>
        <p>There are 3 drawings in the shelf.</p>
        <SortableDrawings/>
      </div>
    );
  }

  renderShelf() {
    if (this.props.renderUnlockedGee) {
      return <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/openShelfGee.png" alt="Open Shelf with gee"/>
    } 
    if (this.props.renderUnlockedDvd) {
      return <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/openShelf.png" alt="Open Shelf"/>
    } 
    return (<img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/closedShelf.png" alt="Closed Dvd shelf"/>);
  }

  render() {
    return (
      <div>
        <div>
          <div className='etr-dvd-shelf'>
            {this.renderShelf()}
          </div>
          <div className='etr-sortable-dvd'>
            <SortableDvds
              setDvds={this.props.setDvds}
              items={this.props.dvds}
            />
          </div>
        </div>
        {this.props.renderUnlockedDvd && this.renderUnlocked()}
      </div>
    );
  }
}

export default DvdContainer;
