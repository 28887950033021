const baseUrl = 'https://animetrivia.xyz/'

export var sendEvent = (userId, eventType, eventValue) => {
	let url = baseUrl + 'etr/api/event';
	let data = {
		userId: userId,
		eventType: eventType,
		eventValue: eventValue
	};
	const otherParam = {
		headers: {
			'content-type': 'application/json; charset=UTF-8'
		},
		body: JSON.stringify(data),
		method: 'POST'
	};
	fetch(url, otherParam)
		.then(data => {return data.json()})
		.then(res => {});
}