import React from 'react';
import Book from '../../components/EscapeTheRoom/Book.jsx'
import SortableBooks from '../../components/EscapeTheRoom/SortableBooks.jsx'
import './bookContainer.css'
import Modal from 'react-modal';

class BookContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openBook: null,
      shouldRenderHollowBookModal: false
    }
  }

  render() {
    return (
      <div className='etr-bookshelf-container'>
        {this.state.openBook &&
          <Modal 
            isOpen={this.state.openBook !== undefined}
            onRequestClose={ () => this.setState({ openBook: null }) }
            ariaHideApp={false} 
            >
            <div>
              <Book
                key={this.state.openBook.title}
                title={this.state.openBook.title}
                imgUrl={this.state.openBook.url}
                author={this.state.openBook.author}
                isbn={this.state.openBook.isbn}
                subjects={this.state.openBook.subjects}
                bookType={this.state.openBook.type}
                notes={this.state.openBook.notes}
              />
            </div>
          </Modal>
        }
        <div>
          <div className='etr-book-shelf'>
            <img src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/bookshelf.png" alt="Bookshelf"/>
          </div>
          <div className='etr-sortable-book'>
            <SortableBooks
              setBooks={this.props.setBooks}
              items={this.props.books}
              openBook={(book) =>  this.setState({openBook: book})}
            />
          </div>
          <div className='etr-hollow-book'>
            <img 
              src="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/flatHollowBook.png" 
              alt="Hollow book on shelf"
              onClick={() => {this.setState({shouldRenderHollowBookModal: true}); this.props.unlockCabinet();}}
            />
          </div>
        </div>
        {
          this.state.shouldRenderHollowBookModal && 
          <Modal 
            isOpen={this.state.shouldRenderHollowBookModal}
            onRequestClose={ () => this.setState({ shouldRenderHollowBookModal: false }) }
            ariaHideApp={false} 
            >
            <div>
              {this.props.shouldRenderKey &&
                <Book
                  imgUrl="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/hollowBookWithKey.png"
                  notes="A hollow book with a key inside. It opens the teacher's cabinet."
                />
              }
              {!this.props.shouldRenderKey &&
                <Book
                  imgUrl="https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/hollowBookNoKey.png"
                  notes="You've used the key found in this book to open the teacher's cabinet."
                />
              }
            </div>
          </Modal>
        }
      </div>
    );
  }
}

export default BookContainer;
