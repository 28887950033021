import React from 'react';
import './book.css'

class Book extends React.Component {

  render() {
    return (
      <div className='etr-book-container'>
        <img src={this.props.imgUrl} alt={this.props.title}/>
        {this.props.title && <p>Title: {this.props.title}</p>}
        {this.props.author && <p>Author: {this.props.author}</p>}
        {this.props.isbn && <p>ISBN: {this.props.isbn}</p>}
        {this.props.subjects && <p>Subjects: {this.props.subjects}</p>}
        {this.props.bookType && <p>Book type: {this.props.bookType}</p>}
        <p>{this.props.notes}</p>
      </div>
    );
  }
}

export default Book;
