import React from 'react';
import './bonusPuzzleLine.css'
import CombinationLock from './CombinationLock.jsx'

class BonusPuzzleLine extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      renderAnswer: false
    }
  }

  render() {
    return (
      <div className='etr-bonus-linewrapper'>
        {this.props.overrideLine && 
          this.props.overrideLine
        }
        {!this.props.overrideLine && 
          <div className='etr-bonus-line-description'><i>{this.props.line}</i></div>
        }
        {this.state.renderAnswer &&
          <div>({this.props.answer})</div>
        }
        {!this.state.renderAnswer &&
          <div>
            <CombinationLock 
              lockName={this.props.line}
              password={this.props.answer}
              unlockAction={() => {this.setState({renderAnswer: true});}}
            />
          </div>
        }
      </div>
    );
  }
}

export default BonusPuzzleLine;
