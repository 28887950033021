import React from 'react';
import './FgoPage.css'
import {Helmet} from 'react-helmet';

class FgoPage extends React.Component {

  render() {
    return (
      <div className="FgoPage">
        <Helmet>
          <meta charSet="utf-8" name="description" content="I built a generic trivia backend framework and used it to serve FGO trivia questions."/>
          <title>FGO Trivia Site</title>
        </Helmet>
        <div className="fgo-title-and-description">
          <h1>FGO Trivia Site</h1>
          <p>Feb. 2018</p>
          <p>
            I originally started out with a general multiplayer trivia site in mind. After several rounds of iterations, I narrowed the scope of the site to just FGO trivia questions. Yet the framework can be used to display questions with various media types from text to images to audio.
          </p>
          <a href="https://www.fgo.animetrivia.io">Here is the link to the trivia site</a>
        </div>
      </div>
    );
  }
}

export default FgoPage;
