import React from 'react';
import Gallery from "react-photo-gallery";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

export var books = [
    {
      title: 'The elegant universe. superstrings, hidden dimensions, and the quest for the ultimate theory. Click or drag me.',
      author: 'Greene, B.',
      isbn: '9780393071344',
      type: 'soft cover',
      subjects: 'cosmology, superstring theories',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/book-the-elegant-universe.png",
      width:6,
      height:9,
      alt:"The elegant universe book. Click or drag me. "
    },
    {
      title: 'Ear on Washington. Click or drag me.',
      author: 'McLellan, Diana',
      isbn: '0877953945',
      type: 'hardback',
      subjects: 'Social life and customs, Politics and government',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/book-ear-on-washington.png",
      width:6,
      height:9,
      alt:"Ear on Washington. Click or drag me. "
    },
    {
      title: 'The adventures of Huckleberry Finn. Click or drag me.',
      author: 'Twain, Mark',
      isbn: '9781620116166',
      type: 'soft cover',
      subjects: 'Finn, Huckleberry (Fictitious character), Boys',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/book-huckleberry-finn.png",
      width:6,
      height:9,
      alt:"The adventures of Huckleberry Finn. Click or drag me."
    },
    {
      title: 'Alien life imagined : communicating the science and culture of astrobiology. Click or drag me.',
      author: 'Brake, Mark',
      isbn: '9780521491297',
      type: 'hardback',
      subjects: 'Unidentified flying object literature, Life on other planets',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/book-alien-life-imagined.png",
      width:6,
      height:9,
      alt:"Alien life imagined. Click or drag me."
    },
    {
      title: 'Lone angler. Click or drag me.',
      author: 'Curtis, Herb',
      isbn: '0864921624',
      type: 'hardback',
      subjects: 'Adventures',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/book-lone-angler.png",
      width:6,
      height:9,
      alt:"Lone angler. Click or drag me."
    },
    {
      title: 'Chess sets. Click or drag me.',
      author: 'Graham, Lanier',
      isbn: '1199424528',
      type: 'hardback',
      subjects: 'Chessmen',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/book-chess-sets.png",
      width:6,
      height:9,
      alt:"Chess sets. Click or drag me."
    },
    {
      title: 'Trump. The art of the deal. Click or drag me.',
      author: 'Trump, Donald',
      isbn: '9780399594496',
      type: 'soft cover',
      subjects: 'Businessmen, Real estate developers',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/book-art-of-the-deal.png",
      width:6,
      height:9,
      alt:"Trump. The art of the deal. Click or drag me."
    },
    {
      title: 'Kafka on the shore. Click or drag me.',
      author: 'Murakami, Haruki',
      isbn: '9781400044818',
      type: 'soft cover',
      subjects: 'Magical realism, fantasy',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/book-kafka-on-the-shore.png",
      width:6,
      height:9,
      alt:"Kafka on the short. Click or drag me."
    },
    {
      title: 'The adventures of Sherlock Holmes. Click or drag me.',
      author: 'Doyle, Arthur Conan',
      isbn: '9781620115091',
      type: 'soft cover',
      subjects: 'Private investigators, Detectives',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/book-sherlock-holmes.png",
      width:6,
      height:9,
      alt:"The adventures of Sherlock Holmes. Click or drag me."
    },
    {
      title: 'Ion. Click or drag me.',
      author: 'Euripides',
      isbn: '0413693309',
      type: 'hardback',
      subjects: 'Greek mythology, Drama',
      src: "https://dmewnhprjcemh.cloudfront.net/assets/escapeTheRoom/book-ion.png",
      width:6,
      height:9,
      alt:"Ion. Click or drag me."
    }
  ]

const imgWithClick = { cursor: "grab" };

const Photo = ({ index, onClick, photo, margin, direction, top, left, alt }) => {
  const imgStyle = { 
    margin: margin, 
    cursor: "grab",
    paddingLeft: "3px",
    paddingRight: "3px",
    width: "calc(20% - 16px)",
    paddingTop: "100px",
    paddingBottom: "100px"
  };
  if (direction === "column") {
    imgStyle.position = "absolute";
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = event => {
    onClick(event, { photo, index });
  };

  return (
    <img
      style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
      {...photo}
      onClick={onClick ? handleClick : null}
      alt={photo.alt}
    />
  );
};

const SortablePhoto = SortableElement(item => 
  <Photo {...item} 
    onClick={() => {
      let url = item.photo.src;
      item.openBook({...item.photo, url: url});
    }}
  />);

const SortableGallery = SortableContainer(({ items, openBook }) => (
  <Gallery 
    photos={items} 
    renderImage={props => 
      <SortablePhoto 
        { ...props }
        openBook={openBook}
      />}
  />
));

class SortableBooks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items
    }
    this.onSortEnd = this.onSortEnd.bind(this);
  }

  onSortEnd({oldIndex, newIndex}) {
    let newItems = arrayMove(this.state.items, oldIndex, newIndex);
    this.setState({items: newItems});
    this.props.setBooks(newItems);
  };

  render() {
    return (
      <div>
        <SortableGallery 
          items={this.state.items} 
          onSortEnd={this.onSortEnd} 
          axis={"xy"} 
          distance={1}
          openBook={this.props.openBook}
        />
      </div>
    )
  }
}

export default SortableBooks;